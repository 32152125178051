import React from 'react';
import './AnimatedText.css';

const text = "freedom \u00A0rebellion\u00A0culture\u00A0competition\u00A0masterpiece\u00A0independence\u00A0aesthetic\u00A0imagination\u00A0revolt\u00A0sovereignty\u00A0expression\u00A0revolution"


function AnimatedText() {
  return (
    <div className="AnimatedText">
      <span className="AnimatedText-content">
        {text.split('').map((char, index) => (
          <span key={index} className="char" style={{ animationDelay: `${index * 0.02}s` }}>
            {char}
          </span>
        ))}
      </span>
    </div>
  );
}

export default AnimatedText;
