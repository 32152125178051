import React from 'react';
import './App.css';
import Header from './Header';
import AnimatedText from './AnimatedText';
import Gallery from './Gallery';
import Image1 from './images/1.png';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import FloatingImagesScene from './FloatingImagesScene';
import NeonButtons from './NeonButtons';

function App() {


  return (
    <>
    <Header />
    <div className='background-image'>
    <Router>
      <NeonButtons />
    </Router>
    </div>
    <Header />
    </>
  );
}

export default App;
