import React, { useRef, useState, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const textureUrlsList = [
  ['./images/1.png', './images/1.png', './images/1.png', './images/1.png', './images/1.png', './images/1.png'],
  ['./images/2.png', './images/2.png', './images/2.png', './images/2.png', './images/2.png', './images/2.png'],
  ['./images/3.png', './images/3.png', './images/3.png', './images/3.png', './images/3.png', './images/3.png'],
  ['./images/4.png', './images/4.png', './images/4.png', './images/4.png', './images/4.png', './images/4.png'],
  ['./images/5.png', './images/5.png', './images/5.png', './images/5.png', './images/5.png', './images/5.png'],
  ['./images/6.png', './images/6.png', './images/6.png', './images/6.png', './images/6.png', './images/6.png'],
  ['./images/7.png', './images/7.png', './images/7.png', './images/7.png', './images/7.png', './images/7.png'],
  ['./images/8.png', './images/8.png', './images/8.png', './images/8.png', './images/8.png', './images/8.png'],
  ['./images/9.png', './images/9.png', './images/9.png', './images/9.png', './images/9.png', './images/9.png'],
  ['./images/10.png', './images/10.png', './images/10.png', './images/10.png', './images/10.png', './images/10.png'],
  ['./images/11.png', './images/11.png', './images/11.png', './images/11.png', './images/11.png', './images/11.png'],
  ['./images/12.png', './images/12.png', './images/12.png', './images/12.png', './images/12.png', './images/12.png'],
  ['./images/x.png', './images/x.png', './images/x.png', './images/x.png', './images/x.png', './images/x.png'],
];

const textures = textureUrlsList.map(urls => {
  return urls.map(url => new THREE.TextureLoader().load(url));
});

const Cube = ({ position, textureUrls }) => {
  const ref = useRef();
  const [scene] = useState(new THREE.Scene()); // Créez une nouvelle scène pour chaque cube

  const [movementDirection, setMovementDirection] = useState(
    new THREE.Vector3(
      Math.random() - 0.5 * 10,
      Math.random() - 0.5 * 10,
      Math.random() - 0.5 * 10
    ).normalize()
  );
  const [speed] = useState(0.01);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setMovementDirection(
      new THREE.Vector3(
        Math.random() - 0.5 * 10,
        Math.random() - 0.5 * 10,
        Math.random() - 0.5 * 10
      ).normalize()
    );
  }, []);

  useFrame(({ scene }) => {
    if (ref.current && !isHovered) {
      ref.current.rotation.x += 0.005;
      ref.current.rotation.y += 0.005;

      ref.current.position.add(movementDirection.clone().multiplyScalar(speed));

      if (ref.current.position.x < -1 || ref.current.position.x > 1) {
        setMovementDirection(new THREE.Vector3(-movementDirection.x, movementDirection.y, movementDirection.z));
      }
      if (ref.current.position.y < -1 || ref.current.position.y > 1) {
        setMovementDirection(new THREE.Vector3(movementDirection.x, -movementDirection.y, movementDirection.z));
      }
      if (ref.current.position.z < -1 || ref.current.position.z > 1 ) {
        setMovementDirection(new THREE.Vector3(movementDirection.x, movementDirection.y, -movementDirection.z));
      }

      // Gestion des collisions entre cubes
      scene.traverse((object) => {
        if (object instanceof THREE.Mesh && object !== ref.current) {
          const distance = ref.current.position.distanceTo(object.position);
          if (distance < 1.5) {
            const newDirection = ref.current.position.clone().sub(object.position).normalize();
            setMovementDirection(newDirection);
          }
        }
      });
    }
  });

  const materials = textureUrls.map((url) => {
    const index = textureUrlsList.findIndex(urls => urls.includes(url));
    return new THREE.MeshBasicMaterial({ map: textures[index][0] });
  });

  const handleSubdivide = () => {
    if (textureUrls.length === 1) {
      const newPosition = ref.current.position.clone();

      for (let i = 0; i < 4; i++) {
        const newCubePosition = newPosition.clone().add(
          new THREE.Vector3(
            (i % 2 === 0 ? -0.25 : 0.25),
            0,
            (i < 2 ? -0.25 : 0.25)
          )
        );

        const childTextures = [...textureUrls];
        childTextures.pop();
        const childCube = (
          <Cube
            key={i}
            position={newCubePosition}
            textureUrls={childTextures}
          />
        );
        scene.add(childCube);
      }

      scene.remove(ref.current);
    }
  };

  const handleClick = () => {
    const xTextureUrl = './images/x.png';
    if (textureUrls.includes(xTextureUrl)) {
      window.location.href = 'https://www.x.com/satoksy';
    } else {
      if (textureUrls.length === 1) {
        handleSubdivide();
      }
    }
  };

  return (
    <mesh
      ref={ref}
      position={position}
      material={materials}
      onPointerEnter={() => setIsHovered(true)}
      onPointerLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <boxGeometry args={[1, 1, 1]} />
    </mesh>
  );
};

export default Cube;
