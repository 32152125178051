import React from 'react';
import './Header.css';
import AnimatedText from './AnimatedText';
import Title from './Title';

function Header() {
  return (
    <header className="Header">
      <div className="Header-background">
            
      <AnimatedText />
      </div>
    </header>
  );
}

export default Header;
