import React from 'react';
import { Link } from 'react-router-dom';
import './NeonButtons.css'; // Assure-toi de créer ce fichier pour les styles

const NeonButtons = () => {
  return (
    <div className="neon-buttons-container">
      <a href="https://ordzaar.com/marketplace/collections/notnodemonkes/" class="neon">Inscribe</a>
      <a href="https://twitter.com/NotNodeMonke" class="neon">X</a>
    </div>
  );
};

export default NeonButtons;

